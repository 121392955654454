import request from '@/api/request.js'

/**
 * @Description: 库位类型
 */

// 分页查询
export function pageWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsBinType(data) {
    return request({
        url: '/api/wmsBinType/batchDelete',
        method: 'POST',
        data
    })
}

